export default `
<object
  type="application/x-shockwave-flash"
  data="/banners/pr_advertising_ads_banner.swf?v=1"
  width="240"
  height="400"
  ><param name="movie" value="/banners/pr_advertising_ads_banner.swf?v=1"
/></object>
<embed
  type="application/x-shockwave-flash"
  src="/banners/pr_advertising_ads_banner.swf?v=1"
  width="240"
  height="400"
/>
`;
