/* score.svelte generated by Svelte v3.59.2 */
import {
	SvelteComponent,
	append_hydration,
	attr,
	children,
	claim_element,
	claim_space,
	claim_text,
	component_subscribe,
	detach,
	element,
	init,
	insert_hydration,
	noop,
	safe_not_equal,
	set_data,
	space,
	text
} from "svelte/internal";

import { t } from "./i18n";
import { activeServicesCount, activeChecksCount, score } from "./model";

function create_fragment(ctx) {
	let h3;
	let div;
	let b;
	let t0;
	let t1;
	let span0;
	let t2_value = t("score.points", /*$score*/ ctx[0], false) + "";
	let t2;
	let t3;
	let t4_value = t("score.outOf") + "";
	let t4;
	let t5;
	let t6;
	let span1;
	let t7;
	let t8_value = t("score.services", /*$activeServicesCount*/ ctx[2]) + "";
	let t8;
	let t9;
	let t10_value = t("score.checks", /*$activeChecksCount*/ ctx[3]) + "";
	let t10;
	let t11;

	return {
		c() {
			h3 = element("h3");
			div = element("div");
			b = element("b");
			t0 = text(/*$score*/ ctx[0]);
			t1 = space();
			span0 = element("span");
			t2 = text(t2_value);
			t3 = space();
			t4 = text(t4_value);
			t5 = text(" 100");
			t6 = space();
			span1 = element("span");
			t7 = text("(");
			t8 = text(t8_value);
			t9 = text(", ");
			t10 = text(t10_value);
			t11 = text(")");
			this.h();
		},
		l(nodes) {
			h3 = claim_element(nodes, "H3", { class: true, "data-color": true });
			var h3_nodes = children(h3);
			div = claim_element(h3_nodes, "DIV", { class: true });
			var div_nodes = children(div);
			b = claim_element(div_nodes, "B", { class: true });
			var b_nodes = children(b);
			t0 = claim_text(b_nodes, /*$score*/ ctx[0]);
			b_nodes.forEach(detach);
			t1 = claim_space(div_nodes);
			span0 = claim_element(div_nodes, "SPAN", { class: true });
			var span0_nodes = children(span0);
			t2 = claim_text(span0_nodes, t2_value);
			t3 = claim_space(span0_nodes);
			t4 = claim_text(span0_nodes, t4_value);
			t5 = claim_text(span0_nodes, " 100");
			span0_nodes.forEach(detach);
			div_nodes.forEach(detach);
			t6 = claim_space(h3_nodes);
			span1 = claim_element(h3_nodes, "SPAN", { class: true });
			var span1_nodes = children(span1);
			t7 = claim_text(span1_nodes, "(");
			t8 = claim_text(span1_nodes, t8_value);
			t9 = claim_text(span1_nodes, ", ");
			t10 = claim_text(span1_nodes, t10_value);
			t11 = claim_text(span1_nodes, ")");
			span1_nodes.forEach(detach);
			h3_nodes.forEach(detach);
			this.h();
		},
		h() {
			attr(b, "class", "final-score-value");
			attr(span0, "class", "final-score-pre-value-text svelte-127ctj");
			attr(div, "class", "final-score-text");
			attr(span1, "class", "final-score-info svelte-127ctj");
			attr(h3, "class", "final-score svelte-127ctj");
			attr(h3, "data-color", /*color*/ ctx[1]);
		},
		m(target, anchor) {
			insert_hydration(target, h3, anchor);
			append_hydration(h3, div);
			append_hydration(div, b);
			append_hydration(b, t0);
			append_hydration(div, t1);
			append_hydration(div, span0);
			append_hydration(span0, t2);
			append_hydration(span0, t3);
			append_hydration(span0, t4);
			append_hydration(span0, t5);
			append_hydration(h3, t6);
			append_hydration(h3, span1);
			append_hydration(span1, t7);
			append_hydration(span1, t8);
			append_hydration(span1, t9);
			append_hydration(span1, t10);
			append_hydration(span1, t11);
		},
		p(ctx, [dirty]) {
			if (dirty & /*$score*/ 1) set_data(t0, /*$score*/ ctx[0]);
			if (dirty & /*$score*/ 1 && t2_value !== (t2_value = t("score.points", /*$score*/ ctx[0], false) + "")) set_data(t2, t2_value);
			if (dirty & /*$activeServicesCount*/ 4 && t8_value !== (t8_value = t("score.services", /*$activeServicesCount*/ ctx[2]) + "")) set_data(t8, t8_value);
			if (dirty & /*$activeChecksCount*/ 8 && t10_value !== (t10_value = t("score.checks", /*$activeChecksCount*/ ctx[3]) + "")) set_data(t10, t10_value);

			if (dirty & /*color*/ 2) {
				attr(h3, "data-color", /*color*/ ctx[1]);
			}
		},
		i: noop,
		o: noop,
		d(detaching) {
			if (detaching) detach(h3);
		}
	};
}

function instance($$self, $$props, $$invalidate) {
	let $score;
	let $activeServicesCount;
	let $activeChecksCount;
	component_subscribe($$self, score, $$value => $$invalidate(0, $score = $$value));
	component_subscribe($$self, activeServicesCount, $$value => $$invalidate(2, $activeServicesCount = $$value));
	component_subscribe($$self, activeChecksCount, $$value => $$invalidate(3, $activeChecksCount = $$value));
	let color = "";

	$$self.$$.update = () => {
		if ($$self.$$.dirty & /*$score*/ 1) {
			$: {
				if ($score >= 75) $$invalidate(1, color = "green");
				if ($score < 75) $$invalidate(1, color = "orange");
				if ($score < 50) $$invalidate(1, color = "pumpkin");
				if ($score < 25) $$invalidate(1, color = "red");
			}
		}
	};

	return [$score, color, $activeServicesCount, $activeChecksCount];
}

class Score extends SvelteComponent {
	constructor(options) {
		super();
		init(this, options, instance, create_fragment, safe_not_equal, {});
	}
}

export default Score;