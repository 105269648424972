/* sections.svelte generated by Svelte v3.59.2 */
import {
	SvelteComponent,
	append_hydration,
	attr,
	check_outros,
	children,
	claim_component,
	claim_element,
	claim_space,
	component_subscribe,
	create_component,
	destroy_component,
	destroy_each,
	detach,
	element,
	empty,
	group_outros,
	init,
	insert_hydration,
	mount_component,
	safe_not_equal,
	space,
	transition_in,
	transition_out
} from "svelte/internal";

import Block from "./block";
import Check from "./check";
import Score from "./score";
import { sections, services, checks } from "./model";

function get_each_context(ctx, list, i) {
	const child_ctx = ctx.slice();
	child_ctx[4] = list[i];
	return child_ctx;
}

function get_each_context_1(ctx, list, i) {
	const child_ctx = ctx.slice();
	child_ctx[7] = list[i];
	child_ctx[9] = i;
	return child_ctx;
}

function get_each_context_2(ctx, list, i) {
	const child_ctx = ctx.slice();
	child_ctx[10] = list[i];
	return child_ctx;
}

// (15:12) {#each $services[serviceId].checks as checkId}
function create_each_block_2(ctx) {
	let check;
	let current;

	check = new Check({
			props: {
				check: /*$checks*/ ctx[2][/*checkId*/ ctx[10]]
			}
		});

	return {
		c() {
			create_component(check.$$.fragment);
		},
		l(nodes) {
			claim_component(check.$$.fragment, nodes);
		},
		m(target, anchor) {
			mount_component(check, target, anchor);
			current = true;
		},
		p(ctx, dirty) {
			const check_changes = {};
			if (dirty & /*$checks, $services, sectionsArr*/ 7) check_changes.check = /*$checks*/ ctx[2][/*checkId*/ ctx[10]];
			check.$set(check_changes);
		},
		i(local) {
			if (current) return;
			transition_in(check.$$.fragment, local);
			current = true;
		},
		o(local) {
			transition_out(check.$$.fragment, local);
			current = false;
		},
		d(detaching) {
			destroy_component(check, detaching);
		}
	};
}

// (13:8) <Block service={$services[serviceId]}>
function create_default_slot_1(ctx) {
	let ol;
	let current;
	let each_value_2 = /*$services*/ ctx[1][/*serviceId*/ ctx[7]].checks;
	let each_blocks = [];

	for (let i = 0; i < each_value_2.length; i += 1) {
		each_blocks[i] = create_each_block_2(get_each_context_2(ctx, each_value_2, i));
	}

	const out = i => transition_out(each_blocks[i], 1, 1, () => {
		each_blocks[i] = null;
	});

	return {
		c() {
			ol = element("ol");

			for (let i = 0; i < each_blocks.length; i += 1) {
				each_blocks[i].c();
			}

			this.h();
		},
		l(nodes) {
			ol = claim_element(nodes, "OL", { class: true });
			var ol_nodes = children(ol);

			for (let i = 0; i < each_blocks.length; i += 1) {
				each_blocks[i].l(ol_nodes);
			}

			ol_nodes.forEach(detach);
			this.h();
		},
		h() {
			attr(ol, "class", "check-list svelte-ln6iwr");
		},
		m(target, anchor) {
			insert_hydration(target, ol, anchor);

			for (let i = 0; i < each_blocks.length; i += 1) {
				if (each_blocks[i]) {
					each_blocks[i].m(ol, null);
				}
			}

			current = true;
		},
		p(ctx, dirty) {
			if (dirty & /*$checks, $services, sectionsArr*/ 7) {
				each_value_2 = /*$services*/ ctx[1][/*serviceId*/ ctx[7]].checks;
				let i;

				for (i = 0; i < each_value_2.length; i += 1) {
					const child_ctx = get_each_context_2(ctx, each_value_2, i);

					if (each_blocks[i]) {
						each_blocks[i].p(child_ctx, dirty);
						transition_in(each_blocks[i], 1);
					} else {
						each_blocks[i] = create_each_block_2(child_ctx);
						each_blocks[i].c();
						transition_in(each_blocks[i], 1);
						each_blocks[i].m(ol, null);
					}
				}

				group_outros();

				for (i = each_value_2.length; i < each_blocks.length; i += 1) {
					out(i);
				}

				check_outros();
			}
		},
		i(local) {
			if (current) return;

			for (let i = 0; i < each_value_2.length; i += 1) {
				transition_in(each_blocks[i]);
			}

			current = true;
		},
		o(local) {
			each_blocks = each_blocks.filter(Boolean);

			for (let i = 0; i < each_blocks.length; i += 1) {
				transition_out(each_blocks[i]);
			}

			current = false;
		},
		d(detaching) {
			if (detaching) detach(ol);
			destroy_each(each_blocks, detaching);
		}
	};
}

// (12:6) {#each section.services as serviceId, i}
function create_each_block_1(ctx) {
	let block;
	let current;

	block = new Block({
			props: {
				service: /*$services*/ ctx[1][/*serviceId*/ ctx[7]],
				$$slots: { default: [create_default_slot_1] },
				$$scope: { ctx }
			}
		});

	return {
		c() {
			create_component(block.$$.fragment);
		},
		l(nodes) {
			claim_component(block.$$.fragment, nodes);
		},
		m(target, anchor) {
			mount_component(block, target, anchor);
			current = true;
		},
		p(ctx, dirty) {
			const block_changes = {};
			if (dirty & /*$services, sectionsArr*/ 3) block_changes.service = /*$services*/ ctx[1][/*serviceId*/ ctx[7]];

			if (dirty & /*$$scope, $services, sectionsArr, $checks*/ 8199) {
				block_changes.$$scope = { dirty, ctx };
			}

			block.$set(block_changes);
		},
		i(local) {
			if (current) return;
			transition_in(block.$$.fragment, local);
			current = true;
		},
		o(local) {
			transition_out(block.$$.fragment, local);
			current = false;
		},
		d(detaching) {
			destroy_component(block, detaching);
		}
	};
}

// (11:4) <Block {section}>
function create_default_slot(ctx) {
	let each_1_anchor;
	let current;
	let each_value_1 = /*section*/ ctx[4].services;
	let each_blocks = [];

	for (let i = 0; i < each_value_1.length; i += 1) {
		each_blocks[i] = create_each_block_1(get_each_context_1(ctx, each_value_1, i));
	}

	const out = i => transition_out(each_blocks[i], 1, 1, () => {
		each_blocks[i] = null;
	});

	return {
		c() {
			for (let i = 0; i < each_blocks.length; i += 1) {
				each_blocks[i].c();
			}

			each_1_anchor = empty();
		},
		l(nodes) {
			for (let i = 0; i < each_blocks.length; i += 1) {
				each_blocks[i].l(nodes);
			}

			each_1_anchor = empty();
		},
		m(target, anchor) {
			for (let i = 0; i < each_blocks.length; i += 1) {
				if (each_blocks[i]) {
					each_blocks[i].m(target, anchor);
				}
			}

			insert_hydration(target, each_1_anchor, anchor);
			current = true;
		},
		p(ctx, dirty) {
			if (dirty & /*$services, sectionsArr, $checks*/ 7) {
				each_value_1 = /*section*/ ctx[4].services;
				let i;

				for (i = 0; i < each_value_1.length; i += 1) {
					const child_ctx = get_each_context_1(ctx, each_value_1, i);

					if (each_blocks[i]) {
						each_blocks[i].p(child_ctx, dirty);
						transition_in(each_blocks[i], 1);
					} else {
						each_blocks[i] = create_each_block_1(child_ctx);
						each_blocks[i].c();
						transition_in(each_blocks[i], 1);
						each_blocks[i].m(each_1_anchor.parentNode, each_1_anchor);
					}
				}

				group_outros();

				for (i = each_value_1.length; i < each_blocks.length; i += 1) {
					out(i);
				}

				check_outros();
			}
		},
		i(local) {
			if (current) return;

			for (let i = 0; i < each_value_1.length; i += 1) {
				transition_in(each_blocks[i]);
			}

			current = true;
		},
		o(local) {
			each_blocks = each_blocks.filter(Boolean);

			for (let i = 0; i < each_blocks.length; i += 1) {
				transition_out(each_blocks[i]);
			}

			current = false;
		},
		d(detaching) {
			destroy_each(each_blocks, detaching);
			if (detaching) detach(each_1_anchor);
		}
	};
}

// (10:2) {#each sectionsArr as section}
function create_each_block(ctx) {
	let block;
	let current;

	block = new Block({
			props: {
				section: /*section*/ ctx[4],
				$$slots: { default: [create_default_slot] },
				$$scope: { ctx }
			}
		});

	return {
		c() {
			create_component(block.$$.fragment);
		},
		l(nodes) {
			claim_component(block.$$.fragment, nodes);
		},
		m(target, anchor) {
			mount_component(block, target, anchor);
			current = true;
		},
		p(ctx, dirty) {
			const block_changes = {};
			if (dirty & /*sectionsArr*/ 1) block_changes.section = /*section*/ ctx[4];

			if (dirty & /*$$scope, sectionsArr, $services, $checks*/ 8199) {
				block_changes.$$scope = { dirty, ctx };
			}

			block.$set(block_changes);
		},
		i(local) {
			if (current) return;
			transition_in(block.$$.fragment, local);
			current = true;
		},
		o(local) {
			transition_out(block.$$.fragment, local);
			current = false;
		},
		d(detaching) {
			destroy_component(block, detaching);
		}
	};
}

function create_fragment(ctx) {
	let div;
	let t;
	let score;
	let current;
	let each_value = /*sectionsArr*/ ctx[0];
	let each_blocks = [];

	for (let i = 0; i < each_value.length; i += 1) {
		each_blocks[i] = create_each_block(get_each_context(ctx, each_value, i));
	}

	const out = i => transition_out(each_blocks[i], 1, 1, () => {
		each_blocks[i] = null;
	});

	score = new Score({});

	return {
		c() {
			div = element("div");

			for (let i = 0; i < each_blocks.length; i += 1) {
				each_blocks[i].c();
			}

			t = space();
			create_component(score.$$.fragment);
			this.h();
		},
		l(nodes) {
			div = claim_element(nodes, "DIV", { class: true });
			var div_nodes = children(div);

			for (let i = 0; i < each_blocks.length; i += 1) {
				each_blocks[i].l(div_nodes);
			}

			t = claim_space(div_nodes);
			claim_component(score.$$.fragment, div_nodes);
			div_nodes.forEach(detach);
			this.h();
		},
		h() {
			attr(div, "class", "section-container svelte-ln6iwr");
		},
		m(target, anchor) {
			insert_hydration(target, div, anchor);

			for (let i = 0; i < each_blocks.length; i += 1) {
				if (each_blocks[i]) {
					each_blocks[i].m(div, null);
				}
			}

			append_hydration(div, t);
			mount_component(score, div, null);
			current = true;
		},
		p(ctx, [dirty]) {
			if (dirty & /*sectionsArr, $services, $checks*/ 7) {
				each_value = /*sectionsArr*/ ctx[0];
				let i;

				for (i = 0; i < each_value.length; i += 1) {
					const child_ctx = get_each_context(ctx, each_value, i);

					if (each_blocks[i]) {
						each_blocks[i].p(child_ctx, dirty);
						transition_in(each_blocks[i], 1);
					} else {
						each_blocks[i] = create_each_block(child_ctx);
						each_blocks[i].c();
						transition_in(each_blocks[i], 1);
						each_blocks[i].m(div, t);
					}
				}

				group_outros();

				for (i = each_value.length; i < each_blocks.length; i += 1) {
					out(i);
				}

				check_outros();
			}
		},
		i(local) {
			if (current) return;

			for (let i = 0; i < each_value.length; i += 1) {
				transition_in(each_blocks[i]);
			}

			transition_in(score.$$.fragment, local);
			current = true;
		},
		o(local) {
			each_blocks = each_blocks.filter(Boolean);

			for (let i = 0; i < each_blocks.length; i += 1) {
				transition_out(each_blocks[i]);
			}

			transition_out(score.$$.fragment, local);
			current = false;
		},
		d(detaching) {
			if (detaching) detach(div);
			destroy_each(each_blocks, detaching);
			destroy_component(score);
		}
	};
}

function instance($$self, $$props, $$invalidate) {
	let sectionsArr;
	let $sections;
	let $services;
	let $checks;
	component_subscribe($$self, sections, $$value => $$invalidate(3, $sections = $$value));
	component_subscribe($$self, services, $$value => $$invalidate(1, $services = $$value));
	component_subscribe($$self, checks, $$value => $$invalidate(2, $checks = $$value));

	$$self.$$.update = () => {
		if ($$self.$$.dirty & /*$sections*/ 8) {
			$: $$invalidate(0, sectionsArr = Object.values($sections));
		}
	};

	return [sectionsArr, $services, $checks, $sections];
}

class Sections extends SvelteComponent {
	constructor(options) {
		super();
		init(this, options, instance, create_fragment, safe_not_equal, {});
	}
}

export default Sections;