/* badge.svelte generated by Svelte v3.59.2 */
import {
	SvelteComponent,
	append_hydration,
	attr,
	children,
	claim_element,
	claim_text,
	detach,
	element,
	init,
	insert_hydration,
	noop,
	safe_not_equal,
	set_data,
	text
} from "svelte/internal";

import { t } from "./i18n";

function create_fragment(ctx) {
	let span;
	let t_1_value = t(`priorities.${/*priority*/ ctx[0]}`) + "";
	let t_1;

	return {
		c() {
			span = element("span");
			t_1 = text(t_1_value);
			this.h();
		},
		l(nodes) {
			span = claim_element(nodes, "SPAN", { class: true, "data-priority": true });
			var span_nodes = children(span);
			t_1 = claim_text(span_nodes, t_1_value);
			span_nodes.forEach(detach);
			this.h();
		},
		h() {
			attr(span, "class", "badge svelte-v1q6by");
			attr(span, "data-priority", /*priority*/ ctx[0]);
		},
		m(target, anchor) {
			insert_hydration(target, span, anchor);
			append_hydration(span, t_1);
		},
		p(ctx, [dirty]) {
			if (dirty & /*priority*/ 1 && t_1_value !== (t_1_value = t(`priorities.${/*priority*/ ctx[0]}`) + "")) set_data(t_1, t_1_value);

			if (dirty & /*priority*/ 1) {
				attr(span, "data-priority", /*priority*/ ctx[0]);
			}
		},
		i: noop,
		o: noop,
		d(detaching) {
			if (detaching) detach(span);
		}
	};
}

function instance($$self, $$props, $$invalidate) {
	let { priority } = $$props;

	$$self.$$set = $$props => {
		if ('priority' in $$props) $$invalidate(0, priority = $$props.priority);
	};

	return [priority];
}

class Badge extends SvelteComponent {
	constructor(options) {
		super();
		init(this, options, instance, create_fragment, safe_not_equal, { priority: 0 });
	}
}

export default Badge;