/* nightMode.svelte generated by Svelte v3.59.2 */
import {
	SvelteComponent,
	add_flush_callback,
	append_hydration,
	attr,
	bind,
	binding_callbacks,
	children,
	claim_component,
	claim_element,
	claim_space,
	claim_text,
	create_component,
	destroy_component,
	detach,
	element,
	init,
	insert_hydration,
	mount_component,
	safe_not_equal,
	space,
	text,
	transition_in,
	transition_out
} from "svelte/internal";

import Switcher from "./switcher";
import { t } from "./i18n";
import { getActiveTheme, saveTheme } from "./utils";

function create_fragment(ctx) {
	let div;
	let label;
	let span;
	let t0_value = t("page.nightMode") + "";
	let t0;
	let t1;
	let switcher;
	let updating_checked;
	let current;

	function switcher_checked_binding(value) {
		/*switcher_checked_binding*/ ctx[2](value);
	}

	let switcher_props = { noLabel: true };

	if (/*isNightMode*/ ctx[0] !== void 0) {
		switcher_props.checked = /*isNightMode*/ ctx[0];
	}

	switcher = new Switcher({ props: switcher_props });
	binding_callbacks.push(() => bind(switcher, 'checked', switcher_checked_binding));
	switcher.$on("change", /*handleChange*/ ctx[1]);

	return {
		c() {
			div = element("div");
			label = element("label");
			span = element("span");
			t0 = text(t0_value);
			t1 = space();
			create_component(switcher.$$.fragment);
			this.h();
		},
		l(nodes) {
			div = claim_element(nodes, "DIV", { class: true });
			var div_nodes = children(div);
			label = claim_element(div_nodes, "LABEL", { class: true });
			var label_nodes = children(label);
			span = claim_element(label_nodes, "SPAN", { class: true });
			var span_nodes = children(span);
			t0 = claim_text(span_nodes, t0_value);
			span_nodes.forEach(detach);
			t1 = claim_space(label_nodes);
			claim_component(switcher.$$.fragment, label_nodes);
			label_nodes.forEach(detach);
			div_nodes.forEach(detach);
			this.h();
		},
		h() {
			attr(span, "class", "text svelte-pqje2v");
			attr(label, "class", "svelte-pqje2v");
			attr(div, "class", "night-mode svelte-pqje2v");
		},
		m(target, anchor) {
			insert_hydration(target, div, anchor);
			append_hydration(div, label);
			append_hydration(label, span);
			append_hydration(span, t0);
			append_hydration(label, t1);
			mount_component(switcher, label, null);
			current = true;
		},
		p(ctx, [dirty]) {
			const switcher_changes = {};

			if (!updating_checked && dirty & /*isNightMode*/ 1) {
				updating_checked = true;
				switcher_changes.checked = /*isNightMode*/ ctx[0];
				add_flush_callback(() => updating_checked = false);
			}

			switcher.$set(switcher_changes);
		},
		i(local) {
			if (current) return;
			transition_in(switcher.$$.fragment, local);
			current = true;
		},
		o(local) {
			transition_out(switcher.$$.fragment, local);
			current = false;
		},
		d(detaching) {
			if (detaching) detach(div);
			destroy_component(switcher);
		}
	};
}

function instance($$self, $$props, $$invalidate) {
	let isNightMode = getActiveTheme();
	let html = document.documentElement;

	function handleChange(event) {
		$$invalidate(0, isNightMode = event.detail.checked);
	}

	function switcher_checked_binding(value) {
		isNightMode = value;
		$$invalidate(0, isNightMode);
	}

	$$self.$$.update = () => {
		if ($$self.$$.dirty & /*isNightMode*/ 1) {
			$: {
				html.classList.add(isNightMode ? "night-mode-on" : "night-mode-off");
				html.classList.remove(isNightMode ? "night-mode-off" : "night-mode-on");
				saveTheme(isNightMode);
			}
		}
	};

	return [isNightMode, handleChange, switcher_checked_binding];
}

class NightMode extends SvelteComponent {
	constructor(options) {
		super();
		init(this, options, instance, create_fragment, safe_not_equal, {});
	}
}

export default NightMode;