/* footer.svelte generated by Svelte v3.59.2 */
import {
	HtmlTagHydration,
	SvelteComponent,
	append_hydration,
	attr,
	children,
	claim_element,
	claim_html_tag,
	claim_space,
	claim_text,
	detach,
	element,
	init,
	insert_hydration,
	noop,
	safe_not_equal,
	space,
	text
} from "svelte/internal";

import { version } from "../package.json";
import { t } from "./i18n";

function create_fragment(ctx) {
	let footer;
	let t0_value = t("page.title") + "";
	let t0;
	let t1;
	let small0;
	let t2;
	let t3_value = t("page.version") + "";
	let t3;
	let t4;
	let t5;
	let t6;
	let t7;
	let br;
	let t8;
	let html_tag;
	let raw_value = t("page.footerQuestion") + "";
	let t9;
	let t10;
	let p;
	let small1;
	let t11_value = t("page.keywords") + "";
	let t11;

	return {
		c() {
			footer = element("footer");
			t0 = text(t0_value);
			t1 = space();
			small0 = element("small");
			t2 = text("(");
			t3 = text(t3_value);
			t4 = text(" ");
			t5 = text(version);
			t6 = text(")");
			t7 = space();
			br = element("br");
			t8 = space();
			html_tag = new HtmlTagHydration(false);
			t9 = text("\n  ym@ymatuhin.ru");
			t10 = space();
			p = element("p");
			small1 = element("small");
			t11 = text(t11_value);
			this.h();
		},
		l(nodes) {
			footer = claim_element(nodes, "FOOTER", { class: true });
			var footer_nodes = children(footer);
			t0 = claim_text(footer_nodes, t0_value);
			t1 = claim_space(footer_nodes);
			small0 = claim_element(footer_nodes, "SMALL", {});
			var small0_nodes = children(small0);
			t2 = claim_text(small0_nodes, "(");
			t3 = claim_text(small0_nodes, t3_value);
			t4 = claim_text(small0_nodes, " ");
			t5 = claim_text(small0_nodes, version);
			t6 = claim_text(small0_nodes, ")");
			small0_nodes.forEach(detach);
			t7 = claim_space(footer_nodes);
			br = claim_element(footer_nodes, "BR", {});
			t8 = claim_space(footer_nodes);
			html_tag = claim_html_tag(footer_nodes, false);
			t9 = claim_text(footer_nodes, "\n  ym@ymatuhin.ru");
			footer_nodes.forEach(detach);
			t10 = claim_space(nodes);
			p = claim_element(nodes, "P", { class: true });
			var p_nodes = children(p);
			small1 = claim_element(p_nodes, "SMALL", {});
			var small1_nodes = children(small1);
			t11 = claim_text(small1_nodes, t11_value);
			small1_nodes.forEach(detach);
			p_nodes.forEach(detach);
			this.h();
		},
		h() {
			html_tag.a = t9;
			attr(footer, "class", "svelte-cwq18q");
			attr(p, "class", "keywords svelte-cwq18q");
		},
		m(target, anchor) {
			insert_hydration(target, footer, anchor);
			append_hydration(footer, t0);
			append_hydration(footer, t1);
			append_hydration(footer, small0);
			append_hydration(small0, t2);
			append_hydration(small0, t3);
			append_hydration(small0, t4);
			append_hydration(small0, t5);
			append_hydration(small0, t6);
			append_hydration(footer, t7);
			append_hydration(footer, br);
			append_hydration(footer, t8);
			html_tag.m(raw_value, footer);
			append_hydration(footer, t9);
			insert_hydration(target, t10, anchor);
			insert_hydration(target, p, anchor);
			append_hydration(p, small1);
			append_hydration(small1, t11);
		},
		p: noop,
		i: noop,
		o: noop,
		d(detaching) {
			if (detaching) detach(footer);
			if (detaching) detach(t10);
			if (detaching) detach(p);
		}
	};
}

class Footer extends SvelteComponent {
	constructor(options) {
		super();
		init(this, options, null, create_fragment, safe_not_equal, {});
	}
}

export default Footer;