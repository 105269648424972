export default `
<script async src="https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js"></script>
<!-- Auto size -->
<ins
  class="adsbygoogle"
  style="display:block"
  data-ad-client="ca-pub-6430039911615607"
  data-ad-slot="4852376176"
  data-ad-format="auto"
></ins>
<script>
  (adsbygoogle = window.adsbygoogle || []).push({});
</script>
`;
