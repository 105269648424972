/* app.svelte generated by Svelte v3.59.2 */
import {
	SvelteComponent,
	claim_component,
	claim_space,
	create_component,
	destroy_component,
	detach,
	init,
	insert_hydration,
	mount_component,
	noop,
	safe_not_equal,
	space,
	transition_in,
	transition_out
} from "svelte/internal";

import { afterUpdate } from 'svelte';
import NightMode from "./nightMode";
import Footer from "./footer";
import TitleAndDescription from "./titleAndDescription";
import Sections from './sections';
import Meta from './meta';

function create_fragment(ctx) {
	let meta;
	let t0;
	let nightmode;
	let t1;
	let titleanddescription;
	let t2;
	let sections;
	let t3;
	let footer;
	let current;
	meta = new Meta({});
	nightmode = new NightMode({});
	titleanddescription = new TitleAndDescription({});
	sections = new Sections({});
	footer = new Footer({});

	return {
		c() {
			create_component(meta.$$.fragment);
			t0 = space();
			create_component(nightmode.$$.fragment);
			t1 = space();
			create_component(titleanddescription.$$.fragment);
			t2 = space();
			create_component(sections.$$.fragment);
			t3 = space();
			create_component(footer.$$.fragment);
		},
		l(nodes) {
			claim_component(meta.$$.fragment, nodes);
			t0 = claim_space(nodes);
			claim_component(nightmode.$$.fragment, nodes);
			t1 = claim_space(nodes);
			claim_component(titleanddescription.$$.fragment, nodes);
			t2 = claim_space(nodes);
			claim_component(sections.$$.fragment, nodes);
			t3 = claim_space(nodes);
			claim_component(footer.$$.fragment, nodes);
		},
		m(target, anchor) {
			mount_component(meta, target, anchor);
			insert_hydration(target, t0, anchor);
			mount_component(nightmode, target, anchor);
			insert_hydration(target, t1, anchor);
			mount_component(titleanddescription, target, anchor);
			insert_hydration(target, t2, anchor);
			mount_component(sections, target, anchor);
			insert_hydration(target, t3, anchor);
			mount_component(footer, target, anchor);
			current = true;
		},
		p: noop,
		i(local) {
			if (current) return;
			transition_in(meta.$$.fragment, local);
			transition_in(nightmode.$$.fragment, local);
			transition_in(titleanddescription.$$.fragment, local);
			transition_in(sections.$$.fragment, local);
			transition_in(footer.$$.fragment, local);
			current = true;
		},
		o(local) {
			transition_out(meta.$$.fragment, local);
			transition_out(nightmode.$$.fragment, local);
			transition_out(titleanddescription.$$.fragment, local);
			transition_out(sections.$$.fragment, local);
			transition_out(footer.$$.fragment, local);
			current = false;
		},
		d(detaching) {
			destroy_component(meta, detaching);
			if (detaching) detach(t0);
			destroy_component(nightmode, detaching);
			if (detaching) detach(t1);
			destroy_component(titleanddescription, detaching);
			if (detaching) detach(t2);
			destroy_component(sections, detaching);
			if (detaching) detach(t3);
			destroy_component(footer, detaching);
		}
	};
}

function instance($$self) {
	if (process.env.NODE_ENV === "production") {
		afterUpdate(() => setTimeout(() => document.dispatchEvent(new Event("prerender-trigger")), 250));
	}

	return [];
}

class App extends SvelteComponent {
	constructor(options) {
		super();
		init(this, options, instance, create_fragment, safe_not_equal, {});
	}
}

export default App;