/* titleAndDescription.svelte generated by Svelte v3.59.2 */
import {
	SvelteComponent,
	append_hydration,
	attr,
	children,
	claim_element,
	claim_space,
	claim_text,
	detach,
	element,
	init,
	insert_hydration,
	noop,
	safe_not_equal,
	space,
	src_url_equal,
	text
} from "svelte/internal";

import logo from "./assets/logo.svg";
import { version, releaseDate } from "../package.json";
import { t } from "./i18n";

function create_fragment(ctx) {
	let div;
	let img;
	let img_src_value;
	let t0;
	let h1;
	let span;
	let t1_value = t("page.title") + "";
	let t1;
	let t2;
	let small;
	let t3;
	let t4_value = t("page.version") + "";
	let t4;
	let t5;
	let t6;
	let t7;
	let t8_value = t("page.release") + "";
	let t8;
	let t9;
	let t10_value = new Date(+releaseDate).toLocaleDateString(process.env.LANG) + "";
	let t10;
	let t11;
	let t12;
	let p0;
	let raw0_value = t("page.description") + "";
	let t13;
	let p1;
	let raw1_value = t("page.warning") + "";

	return {
		c() {
			div = element("div");
			img = element("img");
			t0 = space();
			h1 = element("h1");
			span = element("span");
			t1 = text(t1_value);
			t2 = space();
			small = element("small");
			t3 = text("(");
			t4 = text(t4_value);
			t5 = text(" ");
			t6 = text(version);
			t7 = space();
			t8 = text(t8_value);
			t9 = text(" ");
			t10 = text(t10_value);
			t11 = text(")");
			t12 = space();
			p0 = element("p");
			t13 = space();
			p1 = element("p");
			this.h();
		},
		l(nodes) {
			div = claim_element(nodes, "DIV", { class: true });
			var div_nodes = children(div);
			img = claim_element(div_nodes, "IMG", { src: true, alt: true, class: true });
			t0 = claim_space(div_nodes);
			h1 = claim_element(div_nodes, "H1", { class: true });
			var h1_nodes = children(h1);
			span = claim_element(h1_nodes, "SPAN", { class: true });
			var span_nodes = children(span);
			t1 = claim_text(span_nodes, t1_value);
			span_nodes.forEach(detach);
			t2 = claim_space(h1_nodes);
			small = claim_element(h1_nodes, "SMALL", { class: true });
			var small_nodes = children(small);
			t3 = claim_text(small_nodes, "(");
			t4 = claim_text(small_nodes, t4_value);
			t5 = claim_text(small_nodes, " ");
			t6 = claim_text(small_nodes, version);
			t7 = claim_space(small_nodes);
			t8 = claim_text(small_nodes, t8_value);
			t9 = claim_text(small_nodes, " ");
			t10 = claim_text(small_nodes, t10_value);
			t11 = claim_text(small_nodes, ")");
			small_nodes.forEach(detach);
			h1_nodes.forEach(detach);
			div_nodes.forEach(detach);
			t12 = claim_space(nodes);
			p0 = claim_element(nodes, "P", { class: true });
			var p0_nodes = children(p0);
			p0_nodes.forEach(detach);
			t13 = claim_space(nodes);
			p1 = claim_element(nodes, "P", { class: true });
			var p1_nodes = children(p1);
			p1_nodes.forEach(detach);
			this.h();
		},
		h() {
			if (!src_url_equal(img.src, img_src_value = logo)) attr(img, "src", img_src_value);
			attr(img, "alt", "");
			attr(img, "class", "svelte-i8untx");
			attr(span, "class", "heading-text svelte-i8untx");
			attr(small, "class", "heading-version svelte-i8untx");
			attr(h1, "class", "svelte-i8untx");
			attr(div, "class", "title svelte-i8untx");
			attr(p0, "class", "description");
			attr(p1, "class", "warning svelte-i8untx");
		},
		m(target, anchor) {
			insert_hydration(target, div, anchor);
			append_hydration(div, img);
			append_hydration(div, t0);
			append_hydration(div, h1);
			append_hydration(h1, span);
			append_hydration(span, t1);
			append_hydration(h1, t2);
			append_hydration(h1, small);
			append_hydration(small, t3);
			append_hydration(small, t4);
			append_hydration(small, t5);
			append_hydration(small, t6);
			append_hydration(small, t7);
			append_hydration(small, t8);
			append_hydration(small, t9);
			append_hydration(small, t10);
			append_hydration(small, t11);
			insert_hydration(target, t12, anchor);
			insert_hydration(target, p0, anchor);
			p0.innerHTML = raw0_value;
			insert_hydration(target, t13, anchor);
			insert_hydration(target, p1, anchor);
			p1.innerHTML = raw1_value;
		},
		p: noop,
		i: noop,
		o: noop,
		d(detaching) {
			if (detaching) detach(div);
			if (detaching) detach(t12);
			if (detaching) detach(p0);
			if (detaching) detach(t13);
			if (detaching) detach(p1);
		}
	};
}

class TitleAndDescription extends SvelteComponent {
	constructor(options) {
		super();
		init(this, options, null, create_fragment, safe_not_equal, {});
	}
}

export default TitleAndDescription;