/* check.svelte generated by Svelte v3.59.2 */
import {
	HtmlTagHydration,
	SvelteComponent,
	append_hydration,
	attr,
	binding_callbacks,
	children,
	claim_element,
	claim_html_tag,
	claim_space,
	claim_text,
	detach,
	element,
	empty,
	init,
	insert_hydration,
	listen,
	noop,
	run_all,
	safe_not_equal,
	set_data,
	space,
	text
} from "svelte/internal";

import { onMount } from "svelte";

import {
	EStatus,
	loadScript,
	fetchMediaSize,
	checkSize,
	checkEval,
	proveSize
} from "./model";

import { growingInterval } from "./utils";
import { t } from "./i18n";

function create_if_block_3(ctx) {
	let small;
	let t0;
	let t1;
	let t2;

	return {
		c() {
			small = element("small");
			t0 = text("(");
			t1 = text(/*infoText*/ ctx[4]);
			t2 = text(")");
			this.h();
		},
		l(nodes) {
			small = claim_element(nodes, "SMALL", { class: true });
			var small_nodes = children(small);
			t0 = claim_text(small_nodes, "(");
			t1 = claim_text(small_nodes, /*infoText*/ ctx[4]);
			t2 = claim_text(small_nodes, ")");
			small_nodes.forEach(detach);
			this.h();
		},
		h() {
			attr(small, "class", "infoText svelte-1h1i54z");
		},
		m(target, anchor) {
			insert_hydration(target, small, anchor);
			append_hydration(small, t0);
			append_hydration(small, t1);
			append_hydration(small, t2);
		},
		p(ctx, dirty) {
			if (dirty & /*infoText*/ 16) set_data(t1, /*infoText*/ ctx[4]);
		},
		d(detaching) {
			if (detaching) detach(small);
		}
	};
}

// (63:26) 
function create_if_block_2(ctx) {
	let html_tag;
	let raw_value = /*check*/ ctx[0].include + "";
	let html_anchor;

	return {
		c() {
			html_tag = new HtmlTagHydration(false);
			html_anchor = empty();
			this.h();
		},
		l(nodes) {
			html_tag = claim_html_tag(nodes, false);
			html_anchor = empty();
			this.h();
		},
		h() {
			html_tag.a = html_anchor;
		},
		m(target, anchor) {
			html_tag.m(raw_value, target, anchor);
			insert_hydration(target, html_anchor, anchor);
		},
		p(ctx, dirty) {
			if (dirty & /*check*/ 1 && raw_value !== (raw_value = /*check*/ ctx[0].include + "")) html_tag.p(raw_value);
		},
		d(detaching) {
			if (detaching) detach(html_anchor);
			if (detaching) html_tag.d();
		}
	};
}

// (43:2) {#if check.include && check.type === "size"}
function create_if_block(ctx) {
	let t0;
	let div0;
	let t1;
	let div2;
	let div1;
	let raw_value = /*check*/ ctx[0].include + "";
	let if_block = /*check*/ ctx[0].status === EStatus.likelyUnblocked && create_if_block_1(ctx);

	return {
		c() {
			if (if_block) if_block.c();
			t0 = space();
			div0 = element("div");
			t1 = space();
			div2 = element("div");
			div1 = element("div");
			this.h();
		},
		l(nodes) {
			if (if_block) if_block.l(nodes);
			t0 = claim_space(nodes);
			div0 = claim_element(nodes, "DIV", {});
			children(div0).forEach(detach);
			t1 = claim_space(nodes);
			div2 = claim_element(nodes, "DIV", { class: true });
			var div2_nodes = children(div2);
			div1 = claim_element(div2_nodes, "DIV", { class: true });
			var div1_nodes = children(div1);
			div1_nodes.forEach(detach);
			div2_nodes.forEach(detach);
			this.h();
		},
		h() {
			attr(div1, "class", "include svelte-1h1i54z");
			attr(div2, "class", "includeWrapper svelte-1h1i54z");
		},
		m(target, anchor) {
			if (if_block) if_block.m(target, anchor);
			insert_hydration(target, t0, anchor);
			insert_hydration(target, div0, anchor);
			insert_hydration(target, t1, anchor);
			insert_hydration(target, div2, anchor);
			append_hydration(div2, div1);
			div1.innerHTML = raw_value;
			/*div1_binding*/ ctx[6](div1);
		},
		p(ctx, dirty) {
			if (/*check*/ ctx[0].status === EStatus.likelyUnblocked) {
				if (if_block) {
					if_block.p(ctx, dirty);
				} else {
					if_block = create_if_block_1(ctx);
					if_block.c();
					if_block.m(t0.parentNode, t0);
				}
			} else if (if_block) {
				if_block.d(1);
				if_block = null;
			}

			if (dirty & /*check*/ 1 && raw_value !== (raw_value = /*check*/ ctx[0].include + "")) div1.innerHTML = raw_value;;
		},
		d(detaching) {
			if (if_block) if_block.d(detaching);
			if (detaching) detach(t0);
			if (detaching) detach(div0);
			if (detaching) detach(t1);
			if (detaching) detach(div2);
			/*div1_binding*/ ctx[6](null);
		}
	};
}

// (44:4) {#if check.status === EStatus.likelyUnblocked}
function create_if_block_1(ctx) {
	let div0;
	let t0_value = t("sizeQuestion.description") + "";
	let t0;
	let t1;
	let div1;
	let button0;
	let t2_value = t("sizeQuestion.approve") + "";
	let t2;
	let t3;
	let button1;
	let t4_value = t("sizeQuestion.reject") + "";
	let t4;
	let mounted;
	let dispose;

	return {
		c() {
			div0 = element("div");
			t0 = text(t0_value);
			t1 = space();
			div1 = element("div");
			button0 = element("button");
			t2 = text(t2_value);
			t3 = space();
			button1 = element("button");
			t4 = text(t4_value);
			this.h();
		},
		l(nodes) {
			div0 = claim_element(nodes, "DIV", { class: true });
			var div0_nodes = children(div0);
			t0 = claim_text(div0_nodes, t0_value);
			div0_nodes.forEach(detach);
			t1 = claim_space(nodes);
			div1 = claim_element(nodes, "DIV", { class: true });
			var div1_nodes = children(div1);
			button0 = claim_element(div1_nodes, "BUTTON", { type: true, class: true });
			var button0_nodes = children(button0);
			t2 = claim_text(button0_nodes, t2_value);
			button0_nodes.forEach(detach);
			t3 = claim_space(div1_nodes);
			button1 = claim_element(div1_nodes, "BUTTON", { type: true, class: true });
			var button1_nodes = children(button1);
			t4 = claim_text(button1_nodes, t4_value);
			button1_nodes.forEach(detach);
			div1_nodes.forEach(detach);
			this.h();
		},
		h() {
			attr(div0, "class", "warning svelte-1h1i54z");
			attr(button0, "type", "button");
			attr(button0, "class", "button accept svelte-1h1i54z");
			attr(button1, "type", "button");
			attr(button1, "class", "button reject svelte-1h1i54z");
			attr(div1, "class", "buttons svelte-1h1i54z");
		},
		m(target, anchor) {
			insert_hydration(target, div0, anchor);
			append_hydration(div0, t0);
			insert_hydration(target, t1, anchor);
			insert_hydration(target, div1, anchor);
			append_hydration(div1, button0);
			append_hydration(button0, t2);
			append_hydration(div1, t3);
			append_hydration(div1, button1);
			append_hydration(button1, t4);

			if (!mounted) {
				dispose = [
					listen(button0, "click", /*proveSizeFactory*/ ctx[5](true)),
					listen(button1, "click", /*proveSizeFactory*/ ctx[5](false))
				];

				mounted = true;
			}
		},
		p: noop,
		d(detaching) {
			if (detaching) detach(div0);
			if (detaching) detach(t1);
			if (detaching) detach(div1);
			mounted = false;
			run_all(dispose);
		}
	};
}

function create_fragment(ctx) {
	let li;
	let span1;
	let span0;
	let t0;
	let t1;
	let t2;
	let b;
	let t3;
	let b_data_status_value;
	let t4;
	let t5;
	let if_block0 = /*infoText*/ ctx[4] && create_if_block_3(ctx);

	function select_block_type(ctx, dirty) {
		if (/*check*/ ctx[0].include && /*check*/ ctx[0].type === "size") return create_if_block;
		if (/*check*/ ctx[0].include) return create_if_block_2;
	}

	let current_block_type = select_block_type(ctx, -1);
	let if_block1 = current_block_type && current_block_type(ctx);

	return {
		c() {
			li = element("li");
			span1 = element("span");
			span0 = element("span");
			t0 = text(/*nameText*/ ctx[3]);
			t1 = text(": ");
			t2 = space();
			b = element("b");
			t3 = text(/*statusText*/ ctx[2]);
			t4 = space();
			if (if_block0) if_block0.c();
			t5 = space();
			if (if_block1) if_block1.c();
			this.h();
		},
		l(nodes) {
			li = claim_element(nodes, "LI", { class: true });
			var li_nodes = children(li);
			span1 = claim_element(li_nodes, "SPAN", { class: true });
			var span1_nodes = children(span1);
			span0 = claim_element(span1_nodes, "SPAN", { class: true });
			var span0_nodes = children(span0);
			t0 = claim_text(span0_nodes, /*nameText*/ ctx[3]);
			t1 = claim_text(span0_nodes, ": ");
			span0_nodes.forEach(detach);
			t2 = claim_space(span1_nodes);
			b = claim_element(span1_nodes, "B", { class: true, "data-status": true });
			var b_nodes = children(b);
			t3 = claim_text(b_nodes, /*statusText*/ ctx[2]);
			b_nodes.forEach(detach);
			t4 = claim_space(span1_nodes);
			if (if_block0) if_block0.l(span1_nodes);
			span1_nodes.forEach(detach);
			t5 = claim_space(li_nodes);
			if (if_block1) if_block1.l(li_nodes);
			li_nodes.forEach(detach);
			this.h();
		},
		h() {
			attr(span0, "class", "name");
			attr(b, "class", "status svelte-1h1i54z");
			attr(b, "data-status", b_data_status_value = /*check*/ ctx[0].status);
			attr(span1, "class", "result");
			attr(li, "class", "svelte-1h1i54z");
		},
		m(target, anchor) {
			insert_hydration(target, li, anchor);
			append_hydration(li, span1);
			append_hydration(span1, span0);
			append_hydration(span0, t0);
			append_hydration(span0, t1);
			append_hydration(span1, t2);
			append_hydration(span1, b);
			append_hydration(b, t3);
			append_hydration(span1, t4);
			if (if_block0) if_block0.m(span1, null);
			append_hydration(li, t5);
			if (if_block1) if_block1.m(li, null);
		},
		p(ctx, [dirty]) {
			if (dirty & /*nameText*/ 8) set_data(t0, /*nameText*/ ctx[3]);
			if (dirty & /*statusText*/ 4) set_data(t3, /*statusText*/ ctx[2]);

			if (dirty & /*check*/ 1 && b_data_status_value !== (b_data_status_value = /*check*/ ctx[0].status)) {
				attr(b, "data-status", b_data_status_value);
			}

			if (/*infoText*/ ctx[4]) {
				if (if_block0) {
					if_block0.p(ctx, dirty);
				} else {
					if_block0 = create_if_block_3(ctx);
					if_block0.c();
					if_block0.m(span1, null);
				}
			} else if (if_block0) {
				if_block0.d(1);
				if_block0 = null;
			}

			if (current_block_type === (current_block_type = select_block_type(ctx, dirty)) && if_block1) {
				if_block1.p(ctx, dirty);
			} else {
				if (if_block1) if_block1.d(1);
				if_block1 = current_block_type && current_block_type(ctx);

				if (if_block1) {
					if_block1.c();
					if_block1.m(li, null);
				}
			}
		},
		i: noop,
		o: noop,
		d(detaching) {
			if (detaching) detach(li);
			if (if_block0) if_block0.d();

			if (if_block1) {
				if_block1.d();
			}
		}
	};
}

function instance($$self, $$props, $$invalidate) {
	let { check } = $$props;
	let includeElement;

	// turned off temporary at least to check the google ads corrent way
	let includeVisible = false;

	let statusText = "";
	let nameText = "";
	let infoText = "";
	const proveSizeFactory = proved => () => proveSize({ check, proved, element: includeElement });

	onMount(() => {
		if (check.type === "localLoading") fetchMediaSize(check);
		if (check.type === "script") loadScript(check);
		if (check.type === "size") growingInterval(() => checkSize({ check, element: includeElement }));
		if (check.type === "eval") growingInterval(() => checkEval(check));
	});

	function div1_binding($$value) {
		binding_callbacks[$$value ? 'unshift' : 'push'](() => {
			includeElement = $$value;
			$$invalidate(1, includeElement);
		});
	}

	$$self.$$set = $$props => {
		if ('check' in $$props) $$invalidate(0, check = $$props.check);
	};

	$$self.$$.update = () => {
		if ($$self.$$.dirty & /*check*/ 1) {
			$: {
				includeVisible = check.include && check.type === "size" && check.status === EStatus.likelyBlocked && check.status === EStatus.likelyUnblocked && check.status === EStatus.unblocked;
				$$invalidate(2, statusText = t(`statuses.${check.type}.${check.status}`) || t(`statuses.${check.status}`));
				$$invalidate(3, nameText = t(`checkNames.${check.type}`));
				$$invalidate(4, infoText = t(`statusInfo.${check.type}.${check.status}`) || t(`statusInfo.${check.status}`));
			}
		}
	};

	return [
		check,
		includeElement,
		statusText,
		nameText,
		infoText,
		proveSizeFactory,
		div1_binding
	];
}

class Check extends SvelteComponent {
	constructor(options) {
		super();
		init(this, options, instance, create_fragment, safe_not_equal, { check: 0 });
	}
}

export default Check;