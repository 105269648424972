/* block.svelte generated by Svelte v3.59.2 */
import {
	SvelteComponent,
	append_hydration,
	attr,
	check_outros,
	children,
	claim_component,
	claim_element,
	claim_space,
	claim_text,
	create_component,
	create_slot,
	destroy_component,
	detach,
	element,
	get_all_dirty_from_scope,
	get_slot_changes,
	group_outros,
	init,
	insert_hydration,
	listen,
	mount_component,
	safe_not_equal,
	set_data,
	space,
	text,
	toggle_class,
	transition_in,
	transition_out,
	update_slot_base
} from "svelte/internal";

import Switcher from "./switcher";
import Badge from "./badge";
import { t } from "./i18n";

import {
	changeSectionActive,
	changeSectionVisible,
	changeServiceActive,
	changeServiceVisible
} from "./model";

function create_else_block(ctx) {
	let h4;
	let label;
	let t_1_value = t(/*data*/ ctx[2].titleKey) + "";
	let t_1;

	return {
		c() {
			h4 = element("h4");
			label = element("label");
			t_1 = text(t_1_value);
			this.h();
		},
		l(nodes) {
			h4 = claim_element(nodes, "H4", { class: true });
			var h4_nodes = children(h4);
			label = claim_element(h4_nodes, "LABEL", { for: true, class: true });
			var label_nodes = children(label);
			t_1 = claim_text(label_nodes, t_1_value);
			label_nodes.forEach(detach);
			h4_nodes.forEach(detach);
			this.h();
		},
		h() {
			attr(label, "for", /*id*/ ctx[3]);
			attr(label, "class", "svelte-dpupje");
			attr(h4, "class", "heading svelte-dpupje");
		},
		m(target, anchor) {
			insert_hydration(target, h4, anchor);
			append_hydration(h4, label);
			append_hydration(label, t_1);
		},
		p(ctx, dirty) {
			if (dirty & /*data*/ 4 && t_1_value !== (t_1_value = t(/*data*/ ctx[2].titleKey) + "")) set_data(t_1, t_1_value);

			if (dirty & /*id*/ 8) {
				attr(label, "for", /*id*/ ctx[3]);
			}
		},
		d(detaching) {
			if (detaching) detach(h4);
		}
	};
}

// (31:4) {#if section}
function create_if_block_2(ctx) {
	let h2;
	let label;
	let t_1_value = t(/*data*/ ctx[2].titleKey) + "";
	let t_1;

	return {
		c() {
			h2 = element("h2");
			label = element("label");
			t_1 = text(t_1_value);
			this.h();
		},
		l(nodes) {
			h2 = claim_element(nodes, "H2", { class: true });
			var h2_nodes = children(h2);
			label = claim_element(h2_nodes, "LABEL", { for: true, class: true });
			var label_nodes = children(label);
			t_1 = claim_text(label_nodes, t_1_value);
			label_nodes.forEach(detach);
			h2_nodes.forEach(detach);
			this.h();
		},
		h() {
			attr(label, "for", /*id*/ ctx[3]);
			attr(label, "class", "svelte-dpupje");
			attr(h2, "class", "heading svelte-dpupje");
		},
		m(target, anchor) {
			insert_hydration(target, h2, anchor);
			append_hydration(h2, label);
			append_hydration(label, t_1);
		},
		p(ctx, dirty) {
			if (dirty & /*data*/ 4 && t_1_value !== (t_1_value = t(/*data*/ ctx[2].titleKey) + "")) set_data(t_1, t_1_value);

			if (dirty & /*id*/ 8) {
				attr(label, "for", /*id*/ ctx[3]);
			}
		},
		d(detaching) {
			if (detaching) detach(h2);
		}
	};
}

// (37:4) {#if section}
function create_if_block_1(ctx) {
	let div;
	let badge;
	let current;

	badge = new Badge({
			props: { priority: /*data*/ ctx[2].priority }
		});

	return {
		c() {
			div = element("div");
			create_component(badge.$$.fragment);
			this.h();
		},
		l(nodes) {
			div = claim_element(nodes, "DIV", { class: true });
			var div_nodes = children(div);
			claim_component(badge.$$.fragment, div_nodes);
			div_nodes.forEach(detach);
			this.h();
		},
		h() {
			attr(div, "class", "badge svelte-dpupje");
		},
		m(target, anchor) {
			insert_hydration(target, div, anchor);
			mount_component(badge, div, null);
			current = true;
		},
		p(ctx, dirty) {
			const badge_changes = {};
			if (dirty & /*data*/ 4) badge_changes.priority = /*data*/ ctx[2].priority;
			badge.$set(badge_changes);
		},
		i(local) {
			if (current) return;
			transition_in(badge.$$.fragment, local);
			current = true;
		},
		o(local) {
			transition_out(badge.$$.fragment, local);
			current = false;
		},
		d(detaching) {
			if (detaching) detach(div);
			destroy_component(badge);
		}
	};
}

// (48:4) {#if t(data.descriptionKey)}
function create_if_block(ctx) {
	let p;
	let raw_value = t(/*data*/ ctx[2].descriptionKey) + "";

	return {
		c() {
			p = element("p");
			this.h();
		},
		l(nodes) {
			p = claim_element(nodes, "P", { class: true });
			var p_nodes = children(p);
			p_nodes.forEach(detach);
			this.h();
		},
		h() {
			attr(p, "class", "description svelte-dpupje");
		},
		m(target, anchor) {
			insert_hydration(target, p, anchor);
			p.innerHTML = raw_value;
		},
		p(ctx, dirty) {
			if (dirty & /*data*/ 4 && raw_value !== (raw_value = t(/*data*/ ctx[2].descriptionKey) + "")) p.innerHTML = raw_value;;
		},
		d(detaching) {
			if (detaching) detach(p);
		}
	};
}

function create_fragment(ctx) {
	let div3;
	let div1;
	let div0;
	let switcher;
	let t0;
	let t1;
	let t2;
	let button;

	let t3_value = (/*data*/ ctx[2].visible
	? t("actions.close")
	: t("actions.open")) + "";

	let t3;
	let t4;
	let div2;
	let show_if = t(/*data*/ ctx[2].descriptionKey);
	let t5;
	let current;
	let mounted;
	let dispose;

	switcher = new Switcher({
			props: {
				id: /*id*/ ctx[3],
				small: !!/*service*/ ctx[1],
				checked: /*data*/ ctx[2].active
			}
		});

	switcher.$on("change", /*handleChange*/ ctx[4]("active"));

	function select_block_type(ctx, dirty) {
		if (/*section*/ ctx[0]) return create_if_block_2;
		return create_else_block;
	}

	let current_block_type = select_block_type(ctx, -1);
	let if_block0 = current_block_type(ctx);
	let if_block1 = /*section*/ ctx[0] && create_if_block_1(ctx);
	let if_block2 = show_if && create_if_block(ctx);
	const default_slot_template = /*#slots*/ ctx[6].default;
	const default_slot = create_slot(default_slot_template, ctx, /*$$scope*/ ctx[5], null);

	return {
		c() {
			div3 = element("div");
			div1 = element("div");
			div0 = element("div");
			create_component(switcher.$$.fragment);
			t0 = space();
			if_block0.c();
			t1 = space();
			if (if_block1) if_block1.c();
			t2 = space();
			button = element("button");
			t3 = text(t3_value);
			t4 = space();
			div2 = element("div");
			if (if_block2) if_block2.c();
			t5 = space();
			if (default_slot) default_slot.c();
			this.h();
		},
		l(nodes) {
			div3 = claim_element(nodes, "DIV", { class: true });
			var div3_nodes = children(div3);
			div1 = claim_element(div3_nodes, "DIV", { class: true });
			var div1_nodes = children(div1);
			div0 = claim_element(div1_nodes, "DIV", { class: true });
			var div0_nodes = children(div0);
			claim_component(switcher.$$.fragment, div0_nodes);
			div0_nodes.forEach(detach);
			t0 = claim_space(div1_nodes);
			if_block0.l(div1_nodes);
			t1 = claim_space(div1_nodes);
			if (if_block1) if_block1.l(div1_nodes);
			t2 = claim_space(div1_nodes);
			button = claim_element(div1_nodes, "BUTTON", { type: true, class: true });
			var button_nodes = children(button);
			t3 = claim_text(button_nodes, t3_value);
			button_nodes.forEach(detach);
			div1_nodes.forEach(detach);
			t4 = claim_space(div3_nodes);
			div2 = claim_element(div3_nodes, "DIV", { class: true });
			var div2_nodes = children(div2);
			if (if_block2) if_block2.l(div2_nodes);
			t5 = claim_space(div2_nodes);
			if (default_slot) default_slot.l(div2_nodes);
			div2_nodes.forEach(detach);
			div3_nodes.forEach(detach);
			this.h();
		},
		h() {
			attr(div0, "class", "switcher svelte-dpupje");
			attr(button, "type", "button");
			attr(button, "class", "toggler text-small svelte-dpupje");
			attr(div1, "class", "title svelte-dpupje");
			attr(div2, "class", "content svelte-dpupje");
			toggle_class(div2, "visuallyHidden", !/*data*/ ctx[2].visible);
			attr(div3, "class", "block svelte-dpupje");
			toggle_class(div3, "section", /*section*/ ctx[0]);
			toggle_class(div3, "service", /*service*/ ctx[1]);
			toggle_class(div3, "inactive", !/*data*/ ctx[2].active);
		},
		m(target, anchor) {
			insert_hydration(target, div3, anchor);
			append_hydration(div3, div1);
			append_hydration(div1, div0);
			mount_component(switcher, div0, null);
			append_hydration(div1, t0);
			if_block0.m(div1, null);
			append_hydration(div1, t1);
			if (if_block1) if_block1.m(div1, null);
			append_hydration(div1, t2);
			append_hydration(div1, button);
			append_hydration(button, t3);
			append_hydration(div3, t4);
			append_hydration(div3, div2);
			if (if_block2) if_block2.m(div2, null);
			append_hydration(div2, t5);

			if (default_slot) {
				default_slot.m(div2, null);
			}

			current = true;

			if (!mounted) {
				dispose = listen(button, "click", /*handleChange*/ ctx[4]("visible"));
				mounted = true;
			}
		},
		p(ctx, [dirty]) {
			const switcher_changes = {};
			if (dirty & /*id*/ 8) switcher_changes.id = /*id*/ ctx[3];
			if (dirty & /*service*/ 2) switcher_changes.small = !!/*service*/ ctx[1];
			if (dirty & /*data*/ 4) switcher_changes.checked = /*data*/ ctx[2].active;
			switcher.$set(switcher_changes);

			if (current_block_type === (current_block_type = select_block_type(ctx, dirty)) && if_block0) {
				if_block0.p(ctx, dirty);
			} else {
				if_block0.d(1);
				if_block0 = current_block_type(ctx);

				if (if_block0) {
					if_block0.c();
					if_block0.m(div1, t1);
				}
			}

			if (/*section*/ ctx[0]) {
				if (if_block1) {
					if_block1.p(ctx, dirty);

					if (dirty & /*section*/ 1) {
						transition_in(if_block1, 1);
					}
				} else {
					if_block1 = create_if_block_1(ctx);
					if_block1.c();
					transition_in(if_block1, 1);
					if_block1.m(div1, t2);
				}
			} else if (if_block1) {
				group_outros();

				transition_out(if_block1, 1, 1, () => {
					if_block1 = null;
				});

				check_outros();
			}

			if ((!current || dirty & /*data*/ 4) && t3_value !== (t3_value = (/*data*/ ctx[2].visible
			? t("actions.close")
			: t("actions.open")) + "")) set_data(t3, t3_value);

			if (dirty & /*data*/ 4) show_if = t(/*data*/ ctx[2].descriptionKey);

			if (show_if) {
				if (if_block2) {
					if_block2.p(ctx, dirty);
				} else {
					if_block2 = create_if_block(ctx);
					if_block2.c();
					if_block2.m(div2, t5);
				}
			} else if (if_block2) {
				if_block2.d(1);
				if_block2 = null;
			}

			if (default_slot) {
				if (default_slot.p && (!current || dirty & /*$$scope*/ 32)) {
					update_slot_base(
						default_slot,
						default_slot_template,
						ctx,
						/*$$scope*/ ctx[5],
						!current
						? get_all_dirty_from_scope(/*$$scope*/ ctx[5])
						: get_slot_changes(default_slot_template, /*$$scope*/ ctx[5], dirty, null),
						null
					);
				}
			}

			if (!current || dirty & /*data*/ 4) {
				toggle_class(div2, "visuallyHidden", !/*data*/ ctx[2].visible);
			}

			if (!current || dirty & /*section*/ 1) {
				toggle_class(div3, "section", /*section*/ ctx[0]);
			}

			if (!current || dirty & /*service*/ 2) {
				toggle_class(div3, "service", /*service*/ ctx[1]);
			}

			if (!current || dirty & /*data*/ 4) {
				toggle_class(div3, "inactive", !/*data*/ ctx[2].active);
			}
		},
		i(local) {
			if (current) return;
			transition_in(switcher.$$.fragment, local);
			transition_in(if_block1);
			transition_in(default_slot, local);
			current = true;
		},
		o(local) {
			transition_out(switcher.$$.fragment, local);
			transition_out(if_block1);
			transition_out(default_slot, local);
			current = false;
		},
		d(detaching) {
			if (detaching) detach(div3);
			destroy_component(switcher);
			if_block0.d();
			if (if_block1) if_block1.d();
			if (if_block2) if_block2.d();
			if (default_slot) default_slot.d(detaching);
			mounted = false;
			dispose();
		}
	};
}

function instance($$self, $$props, $$invalidate) {
	let data;
	let id;
	let { $$slots: slots = {}, $$scope } = $$props;
	let { section } = $$props;
	let { service } = $$props;

	const handleChange = property => value => {
		const activeAction = section ? changeSectionActive : changeServiceActive;
		const visibleAction = section ? changeSectionVisible : changeServiceVisible;
		if (property === "active") activeAction({ id, value: !data.active });
		if (property === "visible") visibleAction({ id, value: !data.visible });
	};

	$$self.$$set = $$props => {
		if ('section' in $$props) $$invalidate(0, section = $$props.section);
		if ('service' in $$props) $$invalidate(1, service = $$props.service);
		if ('$$scope' in $$props) $$invalidate(5, $$scope = $$props.$$scope);
	};

	$$self.$$.update = () => {
		if ($$self.$$.dirty & /*section, service*/ 3) {
			$: $$invalidate(2, data = section || service);
		}

		if ($$self.$$.dirty & /*data*/ 4) {
			$: $$invalidate(3, id = data.id);
		}
	};

	return [section, service, data, id, handleChange, $$scope, slots];
}

class Block extends SvelteComponent {
	constructor(options) {
		super();
		init(this, options, instance, create_fragment, safe_not_equal, { section: 0, service: 1 });
	}
}

export default Block;