/* meta.svelte generated by Svelte v3.59.2 */
import {
	SvelteComponent,
	append_hydration,
	attr,
	claim_element,
	destroy_each,
	detach,
	element,
	head_selector,
	init,
	insert_hydration,
	noop,
	safe_not_equal
} from "svelte/internal";

import { t } from "./i18n";
import { hostUrl, alternateUrls } from "./utils/env";

function get_each_context(ctx, list, i) {
	const child_ctx = ctx.slice();
	child_ctx[0] = list[i];
	return child_ctx;
}

// (10:2) {#each alternateUrls as alternate}
function create_each_block(ctx) {
	let link;
	let link_hreflang_value;
	let link_href_value;

	return {
		c() {
			link = element("link");
			this.h();
		},
		l(nodes) {
			link = claim_element(nodes, "LINK", { rel: true, hreflang: true, href: true });
			this.h();
		},
		h() {
			attr(link, "rel", "alternate");
			attr(link, "hreflang", link_hreflang_value = /*alternate*/ ctx[0].locale);
			attr(link, "href", link_href_value = /*alternate*/ ctx[0].url);
		},
		m(target, anchor) {
			insert_hydration(target, link, anchor);
		},
		p: noop,
		d(detaching) {
			if (detaching) detach(link);
		}
	};
}

function create_fragment(ctx) {
	let title_value;
	let meta0;
	let meta0_content_value;
	let link0;
	let link1;
	let link2;
	let link3;
	let link4;
	let link5;
	let meta1;
	let meta2;
	let meta3;
	document.title = title_value = t("meta.title");
	let each_value = alternateUrls;
	let each_blocks = [];

	for (let i = 0; i < each_value.length; i += 1) {
		each_blocks[i] = create_each_block(get_each_context(ctx, each_value, i));
	}

	return {
		c() {
			meta0 = element("meta");
			link0 = element("link");

			for (let i = 0; i < each_blocks.length; i += 1) {
				each_blocks[i].c();
			}

			link1 = element("link");
			link2 = element("link");
			link3 = element("link");
			link4 = element("link");
			link5 = element("link");
			meta1 = element("meta");
			meta2 = element("meta");
			meta3 = element("meta");
			this.h();
		},
		l(nodes) {
			const head_nodes = head_selector('svelte-1k5r5f8', document.head);
			meta0 = claim_element(head_nodes, "META", { name: true, content: true });
			link0 = claim_element(head_nodes, "LINK", { rel: true, href: true });

			for (let i = 0; i < each_blocks.length; i += 1) {
				each_blocks[i].l(head_nodes);
			}

			link1 = claim_element(head_nodes, "LINK", {
				rel: true,
				type: true,
				sizes: true,
				href: true
			});

			link2 = claim_element(head_nodes, "LINK", {
				rel: true,
				type: true,
				sizes: true,
				href: true
			});

			link3 = claim_element(head_nodes, "LINK", { rel: true, href: true });
			link4 = claim_element(head_nodes, "LINK", { rel: true, href: true, color: true });
			link5 = claim_element(head_nodes, "LINK", { rel: true, href: true });
			meta1 = claim_element(head_nodes, "META", { name: true, content: true });
			meta2 = claim_element(head_nodes, "META", { name: true, content: true });
			meta3 = claim_element(head_nodes, "META", { name: true, content: true });
			head_nodes.forEach(detach);
			this.h();
		},
		h() {
			attr(meta0, "name", "description");
			attr(meta0, "content", meta0_content_value = t("meta.description"));
			attr(link0, "rel", "canonical");
			attr(link0, "href", hostUrl);
			attr(link1, "rel", "icon");
			attr(link1, "type", "image/png");
			attr(link1, "sizes", "32x32");
			attr(link1, "href", "/favicon-32x32.png?v=qAJrlpKQ8p");
			attr(link2, "rel", "icon");
			attr(link2, "type", "image/png");
			attr(link2, "sizes", "16x16");
			attr(link2, "href", "/favicon-16x16.png?v=qAJrlpKQ8p");
			attr(link3, "rel", "manifest");
			attr(link3, "href", "/site.webmanifest?v=qAJrlpKQ8p");
			attr(link4, "rel", "mask-icon");
			attr(link4, "href", "/safari-pinned-tab.svg?v=qAJrlpKQ8p");
			attr(link4, "color", "#fd6460");
			attr(link5, "rel", "shortcut icon");
			attr(link5, "href", "/favicon.ico?v=qAJrlpKQ8p");
			attr(meta1, "name", "msapplication-TileColor");
			attr(meta1, "content", "#2d89ef");
			attr(meta2, "name", "msapplication-config");
			attr(meta2, "content", "/browserconfig.xml?v=qAJrlpKQ8p");
			attr(meta3, "name", "theme-color");
			attr(meta3, "content", "#ffffff");
		},
		m(target, anchor) {
			append_hydration(document.head, meta0);
			append_hydration(document.head, link0);

			for (let i = 0; i < each_blocks.length; i += 1) {
				if (each_blocks[i]) {
					each_blocks[i].m(document.head, null);
				}
			}

			append_hydration(document.head, link1);
			append_hydration(document.head, link2);
			append_hydration(document.head, link3);
			append_hydration(document.head, link4);
			append_hydration(document.head, link5);
			append_hydration(document.head, meta1);
			append_hydration(document.head, meta2);
			append_hydration(document.head, meta3);
		},
		p(ctx, [dirty]) {
			if (dirty & /*t*/ 0 && title_value !== (title_value = t("meta.title"))) {
				document.title = title_value;
			}

			if (dirty & /*alternateUrls*/ 0) {
				each_value = alternateUrls;
				let i;

				for (i = 0; i < each_value.length; i += 1) {
					const child_ctx = get_each_context(ctx, each_value, i);

					if (each_blocks[i]) {
						each_blocks[i].p(child_ctx, dirty);
					} else {
						each_blocks[i] = create_each_block(child_ctx);
						each_blocks[i].c();
						each_blocks[i].m(link1.parentNode, link1);
					}
				}

				for (; i < each_blocks.length; i += 1) {
					each_blocks[i].d(1);
				}

				each_blocks.length = each_value.length;
			}
		},
		i: noop,
		o: noop,
		d(detaching) {
			detach(meta0);
			detach(link0);
			destroy_each(each_blocks, detaching);
			detach(link1);
			detach(link2);
			detach(link3);
			detach(link4);
			detach(link5);
			detach(meta1);
			detach(meta2);
			detach(meta3);
		}
	};
}

class Meta extends SvelteComponent {
	constructor(options) {
		super();
		init(this, options, null, create_fragment, safe_not_equal, {});
	}
}

export default Meta;