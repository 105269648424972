/* switcher.svelte generated by Svelte v3.59.2 */
import {
	SvelteComponent,
	append_hydration,
	attr,
	children,
	claim_element,
	claim_space,
	detach,
	element,
	empty,
	init,
	insert_hydration,
	listen,
	noop,
	safe_not_equal,
	space
} from "svelte/internal";

import { createEventDispatcher } from "svelte";

function create_else_block(ctx) {
	let label;
	let input;
	let t;
	let div;
	let label_data_small_value;
	let mounted;
	let dispose;

	return {
		c() {
			label = element("label");
			input = element("input");
			t = space();
			div = element("div");
			this.h();
		},
		l(nodes) {
			label = claim_element(nodes, "LABEL", { class: true, "data-small": true });
			var label_nodes = children(label);
			input = claim_element(label_nodes, "INPUT", { type: true, id: true, class: true });
			t = claim_space(label_nodes);
			div = claim_element(label_nodes, "DIV", { class: true });
			children(div).forEach(detach);
			label_nodes.forEach(detach);
			this.h();
		},
		h() {
			attr(input, "type", "checkbox");
			input.checked = /*checked*/ ctx[1];
			attr(input, "id", /*id*/ ctx[0]);
			attr(input, "class", "svelte-pbsuue");
			attr(div, "class", "slider round svelte-pbsuue");
			attr(label, "class", "switcher svelte-pbsuue");
			attr(label, "data-small", label_data_small_value = !!/*small*/ ctx[2]);
		},
		m(target, anchor) {
			insert_hydration(target, label, anchor);
			append_hydration(label, input);
			append_hydration(label, t);
			append_hydration(label, div);

			if (!mounted) {
				dispose = listen(input, "click", /*handleChange*/ ctx[4]);
				mounted = true;
			}
		},
		p(ctx, dirty) {
			if (dirty & /*checked*/ 2) {
				input.checked = /*checked*/ ctx[1];
			}

			if (dirty & /*id*/ 1) {
				attr(input, "id", /*id*/ ctx[0]);
			}

			if (dirty & /*small*/ 4 && label_data_small_value !== (label_data_small_value = !!/*small*/ ctx[2])) {
				attr(label, "data-small", label_data_small_value);
			}
		},
		d(detaching) {
			if (detaching) detach(label);
			mounted = false;
			dispose();
		}
	};
}

// (15:0) {#if noLabel}
function create_if_block(ctx) {
	let div1;
	let input;
	let t;
	let div0;
	let div1_data_small_value;
	let mounted;
	let dispose;

	return {
		c() {
			div1 = element("div");
			input = element("input");
			t = space();
			div0 = element("div");
			this.h();
		},
		l(nodes) {
			div1 = claim_element(nodes, "DIV", { class: true, "data-small": true });
			var div1_nodes = children(div1);
			input = claim_element(div1_nodes, "INPUT", { type: true, id: true, class: true });
			t = claim_space(div1_nodes);
			div0 = claim_element(div1_nodes, "DIV", { class: true });
			children(div0).forEach(detach);
			div1_nodes.forEach(detach);
			this.h();
		},
		h() {
			attr(input, "type", "checkbox");
			input.checked = /*checked*/ ctx[1];
			attr(input, "id", /*id*/ ctx[0]);
			attr(input, "class", "svelte-pbsuue");
			attr(div0, "class", "slider round svelte-pbsuue");
			attr(div1, "class", "switcher svelte-pbsuue");
			attr(div1, "data-small", div1_data_small_value = !!/*small*/ ctx[2]);
		},
		m(target, anchor) {
			insert_hydration(target, div1, anchor);
			append_hydration(div1, input);
			append_hydration(div1, t);
			append_hydration(div1, div0);

			if (!mounted) {
				dispose = listen(input, "click", /*handleChange*/ ctx[4]);
				mounted = true;
			}
		},
		p(ctx, dirty) {
			if (dirty & /*checked*/ 2) {
				input.checked = /*checked*/ ctx[1];
			}

			if (dirty & /*id*/ 1) {
				attr(input, "id", /*id*/ ctx[0]);
			}

			if (dirty & /*small*/ 4 && div1_data_small_value !== (div1_data_small_value = !!/*small*/ ctx[2])) {
				attr(div1, "data-small", div1_data_small_value);
			}
		},
		d(detaching) {
			if (detaching) detach(div1);
			mounted = false;
			dispose();
		}
	};
}

function create_fragment(ctx) {
	let if_block_anchor;

	function select_block_type(ctx, dirty) {
		if (/*noLabel*/ ctx[3]) return create_if_block;
		return create_else_block;
	}

	let current_block_type = select_block_type(ctx, -1);
	let if_block = current_block_type(ctx);

	return {
		c() {
			if_block.c();
			if_block_anchor = empty();
		},
		l(nodes) {
			if_block.l(nodes);
			if_block_anchor = empty();
		},
		m(target, anchor) {
			if_block.m(target, anchor);
			insert_hydration(target, if_block_anchor, anchor);
		},
		p(ctx, [dirty]) {
			if (current_block_type === (current_block_type = select_block_type(ctx, dirty)) && if_block) {
				if_block.p(ctx, dirty);
			} else {
				if_block.d(1);
				if_block = current_block_type(ctx);

				if (if_block) {
					if_block.c();
					if_block.m(if_block_anchor.parentNode, if_block_anchor);
				}
			}
		},
		i: noop,
		o: noop,
		d(detaching) {
			if_block.d(detaching);
			if (detaching) detach(if_block_anchor);
		}
	};
}

function instance($$self, $$props, $$invalidate) {
	let { id = "" } = $$props;
	let { checked = false } = $$props;
	let { small = false } = $$props;
	let { noLabel = false } = $$props;
	const dispatch = createEventDispatcher();

	function handleChange() {
		dispatch("change", { checked: !checked });
	}

	$$self.$$set = $$props => {
		if ('id' in $$props) $$invalidate(0, id = $$props.id);
		if ('checked' in $$props) $$invalidate(1, checked = $$props.checked);
		if ('small' in $$props) $$invalidate(2, small = $$props.small);
		if ('noLabel' in $$props) $$invalidate(3, noLabel = $$props.noLabel);
	};

	return [id, checked, small, noLabel, handleChange];
}

class Switcher extends SvelteComponent {
	constructor(options) {
		super();
		init(this, options, instance, create_fragment, safe_not_equal, { id: 0, checked: 1, small: 2, noLabel: 3 });
	}
}

export default Switcher;